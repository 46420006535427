import * as yup from 'yup';

import { rulesMap } from '../utils/rules';

export const schema = yup
  .object({
    des_tributacao: yup.string().trim().required(),
    val_icms: yup.string().when('isShowICMS', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.string().required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    val_reducao: yup.string().when('isShowReducao', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.string().required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    cod_situacao_tributaria: yup
      .string()
      .when('tipo_regime', {
        is: (tipoRegime: string) => tipoRegime === '2',
        then: yup.string().notRequired().nullable(true),
        otherwise: yup.string().required('Código é obrigatório'),
      })
      .transform((originalValue) => {
        if (String(originalValue) === '' || String(originalValue).length <= 1)
          return;

        const hasSpecialCharacter = /[^\w]/.test(originalValue);

        if (hasSpecialCharacter) return;

        const paddedValue = originalValue?.padStart(3, '0');
        return paddedValue;
      })
      .test(
        'check-code',
        'Código inválido para o tipo de regime selecionado',
        function (value) {
          if (rulesMap[0] && value !== undefined) {
            const allowedCodes = Object.keys(rulesMap[0]);

            if (!allowedCodes.includes(String(value))) {
              return this.createError({
                message:
                  'CST incompatível para o tipo de tributação selecionado',
              });
            }

            const tipoTributacao = this.parent.tipo_tributacao;

            if (!rulesMap[0][String(value)]?.includes(tipoTributacao.value)) {
              return this.createError({
                message:
                  'CST incompatível para o tipo de tributação selecionado',
              });
            }
          }
          return true;
        },
      ),

    cod_situacao_tributaria_simples: yup
      .string()
      .when('isShowCSON', {
        is: (value: any) => {
          if (value) return true;
          return false;
        },
        then: yup
          .string()
          .required()
          .min(4, 'Deve ter no mínimo 4 caracteres')
          .max(4, 'Deve ter no máximo 4 caracteres'),
        otherwise: yup.string().notRequired().nullable(true),
      })
      .transform((originalValue) => {
        if (String(originalValue) === '' || String(originalValue).length <= 1)
          return;

        const hasSpecialCharacter = /[^\w]/.test(originalValue);

        if (hasSpecialCharacter) return;

        const paddedValue = originalValue?.padStart(3, '0');
        return paddedValue;
      })
      .test(
        'check-code',
        'Código inválido para o tipo de regime selecionado',
        function (value) {
          if (this.parent.tipo_regime === '2') {
            const tipoRegime = this.parent.tipo_regime;

            if (rulesMap[tipoRegime]) {
              const allowedCodes = Object.keys(rulesMap[tipoRegime]);

              if (!allowedCodes.includes(String(value))) {
                return this.createError({
                  message:
                    'CSOSN incompatível para o tipo de tributação selecionado',
                });
              }

              const tipoTributacao = this.parent.tipo_tributacao;

              if (
                !rulesMap[tipoRegime][String(value)].includes(
                  tipoTributacao.value,
                )
              ) {
                return this.createError({
                  message:
                    'CSOSN incompatível para o tipo de tributação selecionado',
                });
              }
            }
          }

          return true;
        },
      ),

    cod_tributacao_pdv: yup.string().notRequired(),
    tipo_tributacao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    tipo_encerramento_diferido: yup.object().when('isShowDiferido', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
  })
  .required();
